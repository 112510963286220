<template>
  <div>
    <v-skeleton-loader
      v-if="isLoading"
      type="table"
    />

    <div v-else>
      <div class="pa-0 px-1 pb-3 w-full d-flex align-center justify-end">
        <v-dialog
          v-model="isFormOpen"
          width="500"
          persistent
        >
          <template #activator="{ on, attrs }">
            <v-btn
              :ripple="false"
              fab
              small
              color="primary"
              v-bind="attrs"
              v-on="on"
              @clikc="currentForm = { ...defaultCurrentFormValue }"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              {{ currentForm.id ? '変動給ユニット編集' : '新規変動給ユニット' }}
            </v-card-title>

            <v-card-text>
              <v-text-field
                v-model="currentForm.name"
                :error="!isValid.name"
                label="名前"
              />

              <v-textarea
                v-model="currentForm.description"
                label="説明"
                rows="2"
                :prepend-icon="icons.mdiCommentQuoteOutline"
              />

              <v-select
                v-model="currentForm.evaluationCycleSpan"
                :items="evaluationCycleSpans"
                item-value="value"
                item-text="text"
                label="評価サイクル"
                :menu-props="{ auto: true, offsetY: true }"
              />

              <v-select
                v-model="currentForm.type"
                :items="types"
                item-value="value"
                item-text="text"
                label="ターゲット"
                :error="!isValid.type"
                :menu-props="{ auto: true, offsetY: true }"
              />

              <v-row class="mt-3">
                <v-col cols="6">
                  <v-text-field
                    :value="currentForm.counter === 'ratio' ? (currentForm.amount * 100.0) : currentForm.amount"
                    type="number"
                    inputmode="numeric"
                    pattern="[0-9]*"
                    :max="currentForm.counter === 'ratio' ? 1 : null"
                    :error="!isValid.amount"
                    label="変動値"
                    min="1"
                    @change="currentForm.amount = currentForm.counter === 'ratio' ? ($event / 100.0) : $event"
                  />
                </v-col>

                <v-col cols="3">
                  <v-select
                    v-model="currentForm.counter"
                    :items="counters"
                    item-value="value"
                    item-text="text"
                    label="単位"
                    :error="!isValid.counter"
                    hide-details
                    :menu-props="{ auto: true, offsetY: true }"
                    @change="
                      currentForm.amount = 1;
                      currentForm.roundingMethod = null;
                      currentForm.roundingDigit = null"
                  />
                </v-col>

                <v-col cols="3">
                  <v-select
                    v-model="currentForm.isNegative"
                    :items="[
                      { value: false, text: '足す' },
                      { value: true, text: '引く' }
                    ]"
                    label="動詞"
                    item-value="value"
                    item-text="text"
                    hide-details
                    :menu-props="{ auto: true, offsetY: true }"
                  />
                </v-col>
              </v-row>

              <v-expand-transition>
                <v-row v-show="currentForm.counter === 'ratio'">
                  <v-col
                    md="6"
                    cols="12"
                  >
                    <v-select
                      v-model="currentForm.roundingMethod"
                      :items="roundingMethods"
                      item-value="value"
                      item-text="text"
                      label="丸め処理"
                      :error="!isValid.roundingMethod"
                      mandatory
                      :menu-props="{ auto: true, offsetY: true }"
                    />
                  </v-col>

                  <v-spacer />

                  <v-col
                    md="6"
                    cols="12"
                  >
                    <v-slider
                      :value="Math.abs(currentForm.roundingDigit)"
                      :tick-labels="['0', '一', '十', '百', '千', '万']"
                      :min="0"
                      :max="5"
                      label="丸める位"
                      step="1"
                      ticks="always"
                      tick-size="4"
                      :error="!isValid.roundingDigit"
                      @change="currentForm.roundingDigit = ($event * -1)"
                    />
                  </v-col>
                </v-row>
              </v-expand-transition>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :ripple="false"
                color="primary"
                :loading="isSubmitting"
                :disabled="!(Object.values(isValid).every(bool => bool))"
                @click="submit"
              >
                {{ currentForm.id ? '更新' : '作成' }}
              </v-btn>
              <v-btn
                :ripple="false"
                color="blue darken-1"
                text
                @click="cancelForm"
              >
                キャンセル
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <v-data-table
        ref="dataTable"
        :key="`${JSON.stringify(paySystemUnits)}`"
        :headers="headers"
        :items="paySystemUnits"
        :header-props="{ sortByText: 'ソート' }"
        hide-default-footer
        disable-pagination
        no-data-text="データがありません"
        show-group-by
        sort-by="groupPosition"
        :expanded="paySystemUnits"
      >
        <template #[`group.header`]="{ groupBy, group, remove }">
          <td
            :ref="groupHeader"
            :colspan="headers.length"
            @click="remove"
          >
            <span class="mr-4">{{ headers.find(obj => obj.value === groupBy[0]).text }}</span>

            <strong v-if="groupBy[0] === 'groupPosition'">
              {{ groupTexts[groupNames[group]] || '随時' }}
            </strong>

            <strong v-if="groupBy[0] === 'attributes.type'">
              {{ typeEnum[group] }}
            </strong>

            <strong v-if="groupBy[0] === 'attributes.amount'">
              <span v-if="+group < 1">{{ group * 100 }}%</span>
              <span v-else-if="+group === 1.0">1円または100%</span>
              <span v-else>¥{{ group.toLocaleString() }}</span>
            </strong>

            <strong v-if="groupBy[0] === 'attributes.counter'">
              {{ counterEnum[group] }}
            </strong>

            <strong v-if="groupBy[0] === 'attributes.roundingMethod'">
              {{ roundingMethodEnum[group] }}
            </strong>

            <strong v-if="groupBy[0] === 'attributes.isNegative'">
              {{ group ? '引く' : '足す' }}
            </strong>
          </td>
        </template>

        <template #[`item.attributes.name`]="{item}">
          <td
            class="text-truncate"
          >
            {{ item.attributes.name }}
          </td>
        </template>

        <template #[`item.attributes.description`]="{item}">
          <td
            class="text-truncate"
            style="max-width: 280px;"
          >
            {{ item.attributes.description }}
          </td>
        </template>

        <template #[`item.groupPosition`]="{ item }">
          <v-chip
            :color="evaluationCycleSpanColors[groupNames[item.groupPosition]] || 'error'"
            :class="`${evaluationCycleSpanColors[groupNames[item.groupPosition]] || 'error'}--text`"
            class="v-chip-light-bg"
          >
            {{ groupTexts[groupNames[item.groupPosition]] || '随時' }}
          </v-chip>
        </template>

        <template #[`item.attributes.amount`]="{ item }">
          {{
            {
              currency_unit: amount => amount.toLocaleString(),
              ratio: amount => amount * 100,
            }[item.attributes.counter](item.attributes.amount)
          }}
        </template>

        <template #[`item.attributes.type`]="{ item }">
          {{ typeEnum[item.attributes.type] }}
        </template>

        <template #[`item.attributes.counter`]="{ item }">
          {{ counterEnum[item.attributes.counter] }}
        </template>

        <template #[`item.attributes.isNegative`]="{ item }">
          {{ item.attributes.isNegative ? '引く' : '足す' }}
        </template>

        <template #[`item.attributes.roundingMethod`]="{ item }">
          <span v-if="item.attributes.roundingMethod">
            {{ roundingMethodEnum[item.attributes.roundingMethod] }}
          </span>
        </template>

        <template #[`item.attributes.roundingDigit`]="{ item }">
          <span v-if="item.attributes.roundingDigit && item.attributes.roundingDigit < 0">
            {{
              {
                1: '一',
                2: '十',
                3: '百',
                4: '千',
                5: '万',
              }[Math.abs(item.attributes.roundingDigit)] + 'の位'
            }}
          </span>
        </template>

        <template #[`item.actions`]="{item}">
          <span class="d-flex align-center justify-end">
            <v-btn
              icon
              :ripple="false"
              @click="
                currentForm = { id: item.id, ...item.attributes };
                isFormOpen = true"
            >
              <v-icon small>
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </v-btn>

            <yona-edit-dialog
              title="削除の確認"
              btn-color="error"
              save-text="同意の上で削除"
              @save="destroy(item.id)"
            >
              <template #append-outer-display-name>
                <v-btn
                  icon
                  :ripple="false"
                >
                  <v-icon small>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
              </template>

              <template #input>
                <span>
                  削除を実行してもよろしいですか
                </span>
              </template>
            </yona-edit-dialog>
          </span>
        </template>

        <template #expanded-item="{ item }">
          <td :colspan="headers.length">
            <v-row>
              <v-col class="ps-4">
                <p class="d-flex align-center flex-wrap">
                  <span class="mr-2">
                    発動条件
                    <span>({{ item.attributes.logics.data.length }})</span>
                  </span>

                  <logic-builder
                    :key="`unit-${item.id}-logics-${JSON.stringify(item.attributes.logics.data)}`"
                    :system-unit-id="+item.id"
                    system-unit-type="paySystemUnit"
                    :system-unit-name="item.attributes.name"
                    :logics="item.attributes.logics.data"
                    :vending-point-conditions="vendingPointConditions"
                    :sales-conditions="salesConditions"
                    :contribution-ratio-conditions="contributionRatioConditions"
                    :vending-size-conditions="vendingSizeConditions"
                    :referring-size-conditions="referringSizeConditions"
                    @changed="currentForm = { id: item.id, logicsAttributes: $event }"
                    @submit="submit"
                  />
                </p>

                <v-treeview
                  v-if="item.attributes.logics.data.length > 0"
                  :items="[{
                    id: `unit-${item.id}-logics`,
                    name: '内容',
                    children: [{ logics: item.attributes.logics.data }]
                  }]"
                >
                  <template #label="{ item: node }">
                    <v-chip-group
                      v-if="node.logics"
                      column
                    >
                      <span
                        v-for="(logic, logicIdx) in node.logics"
                        :key="`unit-${item.id}-${JSON.stringify(logic)}`"
                      >
                        <v-chip>
                          {{ logic.meta.conditionText }}
                        </v-chip>

                        <v-chip
                          v-if="logicIdx !== node.logics.length - 1"
                          color="primary"
                          class="v-chip-light-bg primary--text"
                        >
                          {{ logic.meta.gateText }}
                        </v-chip>
                      </span>
                    </v-chip-group>

                    <span v-else>{{ node.name }}</span>
                  </template>
                </v-treeview>

                <p
                  v-else
                  class="ms-6 secondary--text"
                >
                  常に真
                </p>
              </v-col>
            </v-row>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  ref,
  computed,
  getCurrentInstance,
} from '@vue/composition-api'
import {
  mdiTrashCanOutline,
  mdiPencilOutline,
  mdiPlus,
  mdiCommentQuoteOutline,
  mdiCubeOutline,
} from '@mdi/js'
import {
  map,
  findIndex,
  cloneDeep,
  each,
  flatMap,
  some,
  values,
  every,
} from 'lodash'
import PaySystemUnitApi from '@/api/admin/PaySystemUnit'
import VendingPointConditionApi from '@/api/admin/VendingPointCondition'
import SalesConditionApi from '@/api/admin/SalesCondition'
import ContributionRatioConditionApi from '@/api/admin/ContributionRatioCondition'
import VendingSizeConditionApi from '@/api/admin/VendingSizeCondition'
import ReferringSizeConditionApi from '@/api/admin/ReferringSizeCondition'
import useCompInit from '@/views/composable/useCompInit'
import YonaEditDialog from '@/views/components/util/YonaEditDialog.vue'
import LogicBuilder from '@/views/components/pay_condition/LogicBuilder.vue'

export default {
  components: {
    YonaEditDialog,
    LogicBuilder,
  },
  setup() {
    const vm = getCurrentInstance().proxy
    const { isLoading, initWith } = useCompInit()
    const isFormOpen = ref(false)
    const isSubmitting = ref(false)
    const paySystemUnits = ref([])
    const groupHeader = ref()
    const vendingPointConditions = ref([])
    const salesConditions = ref([])
    const contributionRatioConditions = ref([])
    const vendingSizeConditions = ref([])
    const referringSizeConditions = ref([])
    const defaultCurrentFormValue = {
      id: null,
      name: '',
      description: '',
      evaluationCycleSpan: null,
      type: 'EarningSystemUnit',
      amount: 0,
      isNegative: false,
      counter: 'currency_unit',
      roundingMethod: null,
      roundingDigit: null,
    }
    const currentForm = ref(cloneDeep(defaultCurrentFormValue))
    const groupPositions = {
      daily: 1,
      weekly: 2,
      half_monthly: 3,
      monthly: 4,
    }
    const groupNames = Object.fromEntries(Object.entries(groupPositions).map(a => a.reverse()))
    const types = [
      { value: 'WageSystemUnit', text: '基本給' },
      { value: 'EarningSystemUnit', text: '収入' },
    ]
    const counters = [
      { value: 'currency_unit', text: '円' },
      { value: 'ratio', text: '%' },
    ]
    const roundingMethods = [
      { value: 'round', text: '四捨五入' },
      { value: 'ceil', text: '切り上げ' },
      { value: 'floor', text: '切り捨て' },
    ]
    const typeEnum = types.reduce((obj, val) => ({ ...obj, [val.value]: val.text }), {})
    const counterEnum = counters.reduce((obj, val) => ({ ...obj, [val.value]: val.text }), {})
    const roundingMethodEnum = roundingMethods.reduce((obj, val) => ({ ...obj, [val.value]: val.text }), {})

    const isValid = computed(() => {
      const {
        name,
        type,
        amount,
        counter,
        roundingMethod,
        roundingDigit,
      } = currentForm.value

      return {
        name,
        amount: +amount,
        counter: map(counters, 'value').includes(counter),
        type: map(types, 'value').includes(type),
        roundingMethod: counter === 'ratio' ? roundingMethod : true,
        roundingDigit: counter === 'ratio' ? roundingDigit <= 0 : true,
      }
    })

    const cancelForm = () => {
      isFormOpen.value = false
      isSubmitting.value = false
      currentForm.value = cloneDeep(defaultCurrentFormValue)
    }

    const submit = async () => {
      isSubmitting.value = true

      const isUpdate = !!currentForm.value.id
      const fnVerb = isUpdate ? 'update' : 'create'
      const {
        id,
        name,
        description,
        evaluationCycleSpan,
        amount,
        counter,
        isNegative,
        type,
        roundingMethod,
        roundingDigit,
        logicsAttributes,
      } = currentForm.value

      const res = await PaySystemUnitApi[`${fnVerb}PaySystemUnit`]({
        id,
        name,
        description,
        evaluationCycleSpan,
        amount,
        counter,
        isNegative,
        type,
        roundingMethod,
        roundingDigit,
        logicsAttributes,
      })

      if (res?.data) {
        const { data } = res.data.paySystemUnit
        const paySystemUnit = {
          ...data,
          groupPosition: groupPositions[data.attributes.evaluationCycleSpan],
        }

        if (isUpdate) {
          const paySystemUnitIdx = findIndex(paySystemUnits.value, o => +o.id === +paySystemUnit.id)
          paySystemUnits.value.splice(paySystemUnitIdx, 1, paySystemUnit)
        } else {
          paySystemUnits.value.push(paySystemUnit)
        }

        vm.$toast.success(isUpdate ? '変動給ユニットを更新しました' : '変動給ユニットを追加しました')
      }

      cancelForm()
      isSubmitting.value = false
    }

    const destroy = async id => {
      const res = await PaySystemUnitApi.deletePaySystemUnit(id)

      if (res) {
        const paySystemUnitIdx = findIndex(paySystemUnits.value, o => +o.id === +id)
        paySystemUnits.value.splice(paySystemUnitIdx, 1)
        vm.$toast.success('変動給ユニットを削除しました')
      }
    }

    const getPaySystemUnits = async () => {
      const res = await PaySystemUnitApi.getPaySystemUnits()

      if (res?.data) {
        paySystemUnits.value = [
          ...map(res.data.paySystemUnits.data, paySystemUnit => {
            return {
              ...paySystemUnit,
              groupPosition: groupPositions[paySystemUnit.attributes.evaluationCycleSpan],
            }
          }),
        ]
      }
    }

    const getVendingPointConditions = async () => {
      const res = await VendingPointConditionApi.getVendingPointConditions()
      if (res?.data) {
        vendingPointConditions.value = [...res.data.vendingPointConditions.data]
      }
    }
    const getSalesConditions = async () => {
      const res = await SalesConditionApi.getSalesConditions()
      if (res?.data) {
        salesConditions.value = [...res.data.salesConditions.data]
      }
    }
    const getContributionRatioConditions = async () => {
      const res = await ContributionRatioConditionApi.getContributionRatioConditions()
      if (res?.data) {
        contributionRatioConditions.value = [...res.data.contributionRatioConditions.data]
      }
    }
    const getVendingSizeConditions = async () => {
      const res = await VendingSizeConditionApi.getVendingSizeConditions()
      if (res?.data) {
        vendingSizeConditions.value = [...res.data.vendingSizeConditions.data]
      }
    }
    const getReferringSizeConditions = async () => {
      const res = await ReferringSizeConditionApi.getReferringSizeConditions()
      if (res?.data) {
        referringSizeConditions.value = [...res.data.referringSizeConditions.data]
      }
    }

    initWith([
      getPaySystemUnits(),
      getVendingPointConditions(),
      getSalesConditions(),
      getContributionRatioConditions(),
      getVendingSizeConditions(),
      getReferringSizeConditions(),
    ])

    return {
      // data
      isFormOpen,
      isLoading,
      currentForm,
      isSubmitting,
      paySystemUnits,
      groupHeader,
      headers: [
        {
          text: '名前',
          value: 'attributes.name',
          groupable: false,
        },
        // {
        //   text: '説明',
        //   value: 'attributes.description',
        //   sortable: false,
        //   groupable: false,
        // },
        {
          text: 'ターゲット',
          value: 'attributes.type',
          sortable: false,
        },
        {
          text: '変動値',
          value: 'attributes.amount',
          align: 'right',
        },
        {
          text: '単位',
          value: 'attributes.counter',
          align: 'left',
          sortable: false,
        },
        {
          text: '動詞',
          value: 'attributes.isNegative',
          align: 'left',
          sortable: false,
        },
        {
          text: '丸め処理',
          value: 'attributes.roundingMethod',
          align: 'right',
        },
        {
          text: '丸める位',
          value: 'attributes.roundingDigit',
          align: 'left',
          groupable: false,
        },
        {
          text: '評価サイクル',
          value: 'groupPosition',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          align: 'right',
          sortable: false,
          groupable: false,
        },
      ],
      evaluationCycleSpans: [
        { value: null, text: '随時' },
        { value: 'daily', text: '日ごと' },
        { value: 'weekly', text: '週ごと' },
        { value: 'half_monthly', text: '半期ごと' },
        { value: 'monthly', text: '月ごと' },
      ],
      evaluationCycleSpanColors: {
        daily: 'accent',
        weekly: 'success',
        half_monthly: 'warning',
        monthly: 'secondary',
      },
      groupTexts: {
        daily: '日ごと',
        weekly: '週ごと',
        half_monthly: '半期ごと',
        monthly: '月ごと',
      },
      groupNames,
      types,
      counters,
      roundingMethods,
      typeEnum,
      counterEnum,
      roundingMethodEnum,
      vendingPointConditions,
      salesConditions,
      contributionRatioConditions,
      vendingSizeConditions,
      referringSizeConditions,

      // computed
      isValid,

      // methods
      cancelForm,
      submit,
      destroy,

      icons: {
        mdiTrashCanOutline,
        mdiPencilOutline,
        mdiPlus,
        mdiCommentQuoteOutline,
        mdiCubeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/preset/preset/mixins.scss';

@include theme--child(v-data-table__expanded__content) using ($material) {
  td {
    background-color: map-deep-get($material, 'background');
  }
}

.v-data-table__expanded__content td {
  width: 100vw;
  vertical-align: top;

  &>div:first-child {
    padding: 12px 0 32px 0;

    &>.col {
      background-color: rgba(0,0,0,.05);
    }
  }
}
</style>
